// src/services/authService.ts
import api from './api';

// Define the shape of the login response
interface LoginResponse {
	token: string;
}

// Define the shape of the login request
interface LoginRequest {
	username: string;
	password: string;
}

const authService = {
	/**
	 * Sends a login request to the backend.
	 * @param username - The user's username.
	 * @param password - The user's password.
	 * @returns A promise that resolves to the JWT token.
	 */
	login: async (username: string, password: string): Promise<string> => {
		const response = await api.post<LoginResponse>('/api/Auth/Login', {
			username,
			password,
		});
		return response.data.token;
	},

	/**
	 * (Optional) You can add more authentication-related methods here,
	 * such as logout, register, refreshToken, etc.
	 */
};

export default authService;
