// Updated Products.tsx
import React, { useState, useEffect } from 'react';
import { ProductReadDto, CategoryReadDto } from '../../types';
import productService from '../../services/productService';
import categoryService from '../../services/categoryService';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import BasicProductForm from './BasicProductForm';
import ConfirmDialog from '../common/ConfirmDialog';

const Products: React.FC = () => {
	const [products, setProducts] = useState<ProductReadDto[]>([]);
	const [categories, setCategories] = useState<CategoryReadDto[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [selectedProduct, setSelectedProduct] = useState<ProductReadDto | null>(
		null
	);
	const [openForm, setOpenForm] = useState(false);
	const [openConfirm, setOpenConfirm] = useState(false);
	const [productToDelete, setProductToDelete] = useState<number | null>(null);

	const fetchProducts = async () => {
		try {
			setLoading(true);
			const data = await productService.getProducts();
			setProducts(data);
		} catch (error) {
			console.error('Failed to fetch products:', error);
		} finally {
			setLoading(false);
		}
	};

	const fetchCategories = async () => {
		try {
			const data = await categoryService.getCategories();
			setCategories(data);
		} catch (error) {
			console.error('Failed to fetch categories:', error);
		}
	};

	useEffect(() => {
		fetchProducts();
		fetchCategories();
	}, []);

	const handleAdd = () => {
		setSelectedProduct(null);
		setOpenForm(true);
	};

	const handleEdit = (product: ProductReadDto) => {
		setSelectedProduct(product);
		setOpenForm(true);
	};

	const handleDelete = (id: number) => {
		setProductToDelete(id);
		setOpenConfirm(true);
	};

	const confirmDelete = async () => {
		if (productToDelete !== null) {
			try {
				await productService.deleteProduct(productToDelete);
				setProducts((prev) =>
					prev.filter((product) => product.productId !== productToDelete)
				);
			} catch (error) {
				console.error('Failed to delete product:', error);
			} finally {
				setOpenConfirm(false);
				setProductToDelete(null);
			}
		}
	};

	const handleFormSubmit = async (formData: FormData) => {
		try {
			if (selectedProduct) {
				await productService.updateProduct(selectedProduct.productId, formData);
			} else {
				await productService.createProduct(formData);
			}
			fetchProducts();
		} catch (error) {
			console.error('Failed to submit product form:', error);
		} finally {
			setOpenForm(false);
		}
	};

	const toggleSoldStatus = async (productId: number) => {
		try {
			await productService.toggleSoldStatus(productId);
			fetchProducts(); // Refresh the list
		} catch (error) {
			console.error('Failed to toggle sold status:', error);
		}
	};

	if (loading) return <CircularProgress />;

	return (
		<>
			<Button
				variant="contained"
				color="primary"
				onClick={handleAdd}
				sx={{ mb: 2 }}>
				Add Product
			</Button>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>ID</TableCell>
							<TableCell>Title</TableCell>
							<TableCell>Sold</TableCell> {/* New column */}
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{products.map((product) => (
							<TableRow key={product.productId}>
								<TableCell>{product.productId}</TableCell>
								<TableCell>{product.productTitle}</TableCell>
								<TableCell>
									{product.sold ? (
										<span style={{ color: 'green', fontWeight: 'bold' }}>
											Yes
										</span>
									) : (
										<span style={{ color: 'red', fontWeight: 'bold' }}>No</span>
									)}
								</TableCell>
								<TableCell>
									<IconButton onClick={() => handleEdit(product)}>
										<EditIcon />
									</IconButton>
									<IconButton onClick={() => handleDelete(product.productId)}>
										<DeleteIcon />
									</IconButton>
									<IconButton
										onClick={() => toggleSoldStatus(product.productId)}
										color={product.sold ? 'success' : 'default'}>
										{product.sold ? <CheckIcon /> : <CloseIcon />}
									</IconButton>
								</TableCell>
							</TableRow>
						))}
						{products.length === 0 && (
							<TableRow>
								<TableCell
									colSpan={4}
									align="center">
									No products found.
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>

			<Dialog
				open={openForm}
				onClose={() => setOpenForm(false)}
				fullWidth>
				<DialogTitle>
					{selectedProduct ? 'Edit Product' : 'Add Product'}
				</DialogTitle>
				<DialogContent>
					<BasicProductForm
						existingProduct={selectedProduct}
						categories={categories}
						onSuccess={handleFormSubmit}
						onCancel={() => setOpenForm(false)}
					/>
				</DialogContent>
			</Dialog>

			<ConfirmDialog
				open={openConfirm}
				title="Confirm Deletion"
				content="Are you sure you want to delete this product?"
				onConfirm={confirmDelete}
				onCancel={() => setOpenConfirm(false)}
			/>
		</>
	);
};

export default Products;
