import api from './api';
import { CategoryCreateDto, CategoryReadDto } from '../types';

const getCategories = async (): Promise<CategoryReadDto[]> => {
	const response = await api.get('/api/Categories');
	return response.data;
};

const getCategoryById = async (id: number): Promise<CategoryReadDto> => {
	const response = await api.get(`/api/Categories/${id}`);
	return response.data;
};

const createCategory = async (
	category: CategoryCreateDto
): Promise<CategoryReadDto> => {
	const response = await api.post('/api/Categories', category);
	return response.data;
};

const updateCategory = async (
	id: number,
	categoryData: { categoryName: string }
) => {
	const response = await api.put(`/api/Categories/${id}`, categoryData);
	return response.data;
};

const deleteCategory = async (id: number): Promise<void> => {
	await api.delete(`/api/Categories/${id}`);
};

export default {
	getCategories,
	getCategoryById,
	createCategory,
	updateCategory,
	deleteCategory,
};
