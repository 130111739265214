import React from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import {
	AppBar,
	Toolbar,
	Typography,
	Button,
	Drawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	CssBaseline,
	Box,
    ListItemButton,
} from '@mui/material';
import CategoryIcon from '@mui/icons-material/Category';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../../contexts/AuthContext';

const drawerWidth = 240;

const Dashboard: React.FC = () => {
	const { logout } = useAuth();
	const navigate = useNavigate();

	const handleLogout = () => {
		logout();
		navigate('/login');
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			{/* AppBar */}
			<AppBar
				position="fixed"
				sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
				<Toolbar>
					<Typography
						variant="h6"
						noWrap
						component="div">
						Dashboard
					</Typography>
					<Box sx={{ flexGrow: 1 }} />
					<Button
						color="inherit"
						startIcon={<LogoutIcon />}
						onClick={handleLogout}>
						Logout
					</Button>
				</Toolbar>
			</AppBar>

			{/* Drawer */}
			<Drawer
				variant="permanent"
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					[`& .MuiDrawer-paper`]: {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
				}}>
				<Toolbar />
				<Box sx={{ overflow: 'auto' }}>
					<List>
						<ListItem
							component={Link}
							to="/categories">
							<ListItemButton>
								<ListItemIcon>
									<CategoryIcon />
								</ListItemIcon>
								<ListItemText primary="Categories" />
							</ListItemButton>
						</ListItem>
						<ListItem
							component={Link}
							to="/products">
							<ListItemButton>
								<ListItemIcon>
									<ShoppingCartIcon />
								</ListItemIcon>
								<ListItemText primary="Products" />
							</ListItemButton>
						</ListItem>
						{/* Add more navigation items as needed */}
					</List>
				</Box>
			</Drawer>

			{/* Main Content */}
			<Box
				component="main"
				sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
				<Toolbar />
				<Outlet />
			</Box>
		</Box>
	);
};

export default Dashboard;
