import React from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from 'react-router-dom';
import Login from './components/Auth/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Categories from './components/Categories/Categories';
import Products from './components/Products/Products';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { CategoriesProvider } from './contexts/CategoriesContext';

const PrivateRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
	const { isAuthenticated } = useAuth();
	return isAuthenticated ? children : <Navigate to="/login" />;
};

const App: React.FC = () => {
	return (
		<AuthProvider>
			<Router>
				<Routes>
					<Route
						path="/login"
						element={<Login />}
					/>
					<Route
						path="/"
						element={
							<PrivateRoute>
								<CategoriesProvider>
									<Dashboard />
								</CategoriesProvider>
							</PrivateRoute>
						}>
						<Route
							path="categories"
							element={
								<PrivateRoute>
									<CategoriesProvider>
										<Categories />
									</CategoriesProvider>
								</PrivateRoute>
							}
						/>
						<Route
							path="products"
							element={
								<PrivateRoute>
									<CategoriesProvider>
										<Products />
									</CategoriesProvider>
								</PrivateRoute>
							}
						/>
						{/* Add more nested routes as needed */}
					</Route>
					{/* Redirect any unknown routes to login */}
					<Route
						path="*"
						element={<Navigate to="/login" />}
					/>
				</Routes>
			</Router>
		</AuthProvider>
	);
};

export default App;
