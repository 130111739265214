import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@mui/material';

interface ConfirmDialogProps {
	open: boolean;
	title: string;
	content: string;
	onConfirm: () => void;
	onCancel: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
	open,
	title,
	content,
	onConfirm,
	onCancel,
}) => {
	return (
		<Dialog
			open={open}
			onClose={onCancel}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{content}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel}>No</Button>
				<Button
					onClick={onConfirm}
					color="secondary">
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmDialog;
