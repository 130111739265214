import React, { useState } from 'react';
import {
	TextField,
	Button,
	Box,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
	Typography,
	IconButton,
	ImageList,
	ImageListItem,
} from '@mui/material';
import { ProductCreateDto, ProductReadDto, CategoryReadDto } from '../../types';
import DeleteIcon from '@mui/icons-material/Delete';
import { SelectChangeEvent } from '@mui/material';

interface BasicProductFormProps {
	existingProduct: ProductReadDto | null;
	categories: CategoryReadDto[];
	onSuccess: (productData: FormData) => void;
	onCancel: () => void;
}

const BasicProductForm: React.FC<BasicProductFormProps> = ({
	existingProduct,
	categories,
	onSuccess,
	onCancel,
}) => {
	const [images, setImages] = useState<FileList | null>(null);
	const [existingImages, setExistingImages] = useState<string[]>(
		existingProduct?.imagePaths || []
	);
	const [formData, setFormData] = useState<ProductCreateDto>({
		productTitle: existingProduct?.productTitle || '',
		productDescription: existingProduct?.productDescription || '',
		price: existingProduct?.price || 0,
		costPrice: existingProduct?.costPrice || 0,
		source: existingProduct?.source || '',
		categoryId: existingProduct?.categoryId || 0,
	});

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

const handleCategoryChange = (event: SelectChangeEvent<number>) => {
	setFormData((prev) => ({
		...prev,
		categoryId: parseInt(event.target.value as string, 10), // Ensure value is parsed to number
	}));
};

	const handleDeleteExistingImage = (imagePath: string) => {
		setExistingImages((prev) => prev.filter((img) => img !== imagePath));
	};

const handleSubmit = () => {
	const data = new FormData();

	// Append form fields
	Object.entries(formData).forEach(([key, value]) => {
		if (value !== undefined && value !== null) {
			data.append(key, value.toString());
		}
	});

	// Include existing images
	data.append('ExistingImagesJson', JSON.stringify(existingImages));

	// Include new images
	if (images && images.length > 0) {
		Array.from(images).forEach((image) => {
			data.append('Images', image);
		});
	}

	onSuccess(data);
};


	return (
		<Box>
			<TextField
				name="productTitle"
				label="Product Title"
				value={formData.productTitle}
				onChange={handleChange}
				fullWidth
				margin="normal"
			/>
			<TextField
				name="productDescription"
				label="Product Description"
				value={formData.productDescription}
				onChange={handleChange}
				fullWidth
				margin="normal"
				multiline
				rows={4}
			/>
			<TextField
				name="price"
				label="Price"
				type="number"
				value={formData.price}
				onChange={handleChange}
				fullWidth
				margin="normal"
			/>
			<TextField
				name="costPrice"
				label="Cost Price"
				type="number"
				value={formData.costPrice}
				onChange={handleChange}
				fullWidth
				margin="normal"
			/>
			<TextField
				name="source"
				label="Source"
				value={formData.source}
				onChange={handleChange}
				fullWidth
				margin="normal"
			/>
			<FormControl
				fullWidth
				margin="normal">
				<InputLabel id="category-label">Category</InputLabel>
				<Select
					labelId="category-label"
					value={formData.categoryId}
					onChange={handleCategoryChange}>
					{categories.map((category) => (
						<MenuItem
							key={category.categoryId}
							value={category.categoryId}>
							{category.categoryName}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{existingImages.length > 0 && (
				<Box sx={{ mt: 2 }}>
					<Typography variant="subtitle1">Existing Images:</Typography>
					<ImageList
						cols={4}
						rowHeight={100}>
						{existingImages.map((imagePath, index) => (
							<ImageListItem key={index}>
								<img
									src={`/uploads/${imagePath}`}
									alt={`Image ${index}`}
								/>
								<IconButton
									color="error"
									onClick={() => handleDeleteExistingImage(imagePath)}>
									<DeleteIcon />
								</IconButton>
							</ImageListItem>
						))}
					</ImageList>
				</Box>
			)}
			<Button
				variant="contained"
				component="label"
				sx={{ mt: 2 }}>
				Upload Images
				<input
					type="file"
					hidden
					multiple
					onChange={(e) => setImages(e.target.files)}
				/>
			</Button>
			<Box sx={{ mt: 2 }}>
				<Button
					variant="contained"
					color="primary"
					onClick={handleSubmit}>
					{existingProduct ? 'Update' : 'Create'}
				</Button>
				<Button
					variant="outlined"
					color="secondary"
					onClick={onCancel}
					sx={{ ml: 2 }}>
					Cancel
				</Button>
			</Box>
		</Box>
	);
};

export default BasicProductForm;
