import api from './api';
import { ProductCreateDto, ProductReadDto, ProductUpdateDto } from '../types';

// Fetch all products
const getProducts = async (): Promise<ProductReadDto[]> => {
	const response = await api.get('/api/Products');
	return response.data;
};

// Delete a product by ID
const deleteProduct = async (productId: number): Promise<void> => {
	await api.delete(`/api/Products/${productId}`);
};

// Other product-related functions
const createProduct = async (
	data: ProductCreateDto | FormData
): Promise<ProductReadDto> => {
	return await handleProductRequest('POST', '/api/Products', data);
};

export const updateProduct = async (
	productId: number,
	data: FormData
): Promise<ProductReadDto> => {
	try {
		const response = await api.put(`/api/Products/${productId}`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return response.data;
	} catch (error) {
		console.error('Error during product update:', error);
		throw error;
	}
};

const handleProductRequest = async (
	method: 'POST' | 'PUT',
	url: string,
	data: ProductCreateDto | ProductUpdateDto | FormData
): Promise<ProductReadDto> => {
	const headers =
		data instanceof FormData ? { 'Content-Type': 'multipart/form-data' } : {};
	const response = await api.request({
		method,
		url,
		data,
		headers,
	});
	return response.data;
};

const toggleSoldStatus = async (productId: number): Promise<void> => {
	await api.patch(`/api/Products/${productId}/toggle-sold`);
};

// Export all functions
export default {
	getProducts,
	deleteProduct,
	createProduct,
	updateProduct,
	toggleSoldStatus,
};
