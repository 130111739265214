import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	TextField,
	Button,
	Container,
	Typography,
	Box,
	Alert,
} from '@mui/material';

interface LoginFormInputs {
	username: string;
	password: string;
}

const schema = yup.object().shape({
	username: yup.string().required('Username is required'),
	password: yup.string().required('Password is required'),
});

const Login: React.FC = () => {
	const { login } = useAuth();
	const navigate = useNavigate();
	const [error, setError] = React.useState<string | null>(null);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<LoginFormInputs>({
		resolver: yupResolver(schema),
	});

	const onSubmit: SubmitHandler<LoginFormInputs> = async (data) => {
		const success = await login(data.username, data.password);
		if (success) {
			navigate('/');
		} else {
			setError('Invalid username or password');
		}
	};

	return (
		<Container maxWidth="sm">
			<Box sx={{ mt: 8 }}>
				<Typography
					variant="h4"
					component="h1"
					gutterBottom>
					Login
				</Typography>
				{error && <Alert severity="error">{error}</Alert>}
				<form onSubmit={handleSubmit(onSubmit)}>
					<TextField
						label="Username"
						fullWidth
						margin="normal"
						{...register('username')}
						error={!!errors.username}
						helperText={errors.username?.message}
					/>
					<TextField
						label="Password"
						type="password"
						fullWidth
						margin="normal"
						{...register('password')}
						error={!!errors.password}
						helperText={errors.password?.message}
					/>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						sx={{ mt: 2 }}>
						Login
					</Button>
				</form>
			</Box>
		</Container>
	);
};

export default Login;
