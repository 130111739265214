import React from 'react';
import ReactDOM from 'react-dom/client'; // Note the new import path for createRoot
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';

// Find the root element in your HTML where the React app is mounted
const rootElement = document.getElementById('root');

// Add TypeScript type checking to ensure rootElement is not null
if (rootElement) {
	const root = ReactDOM.createRoot(rootElement as HTMLElement);
	root.render(
		//<React.StrictMode>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
		//</React.StrictMode>
	);
}
