// src/api.ts
import axios from 'axios';

console.log('API Base URL:', process.env.REACT_APP_API_BASE_URL);
console.log('API Key:', process.env.REACT_APP_API_KEY);
console.log('Environment:', process.env);

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Add a request interceptor to include the API key
api.interceptors.request.use(
  (config) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    if (apiKey) {
      config.headers['X-API-KEY'] = apiKey;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
