import React, { createContext, useState, useEffect, useContext } from 'react';
import { CategoryReadDto } from '../types';
import categoryService from '../services/categoryService';

interface CategoriesContextType {
	categories: CategoryReadDto[];
	refreshCategories: () => void;
}

const CategoriesContext = createContext<CategoriesContextType>({
	categories: [],
	refreshCategories: () => {},
});

export const CategoriesProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [categories, setCategories] = useState<CategoryReadDto[]>([]);

	const fetchCategories = async () => {
		try {
			const data = await categoryService.getCategories();
			setCategories(data);
		} catch (error) {
			console.error('Failed to fetch categories:', error);
		}
	};

	useEffect(() => {
		fetchCategories();
	}, []);

	const refreshCategories = () => {
		fetchCategories();
	};

	return (
		<CategoriesContext.Provider value={{ categories, refreshCategories }}>
			{children}
		</CategoriesContext.Provider>
	);
};

export const useCategories = () => useContext(CategoriesContext);
