// src/AuthContext.tsx
import React, { createContext, useState, useEffect, useContext } from 'react';
import authService from '../services/authService'; // Adjust the path as needed
import axios from 'axios';

interface AuthContextType {
	isAuthenticated: boolean;
	token: string | null;
	login: (username: string, password: string) => Promise<boolean>;
	logout: () => void;
}

const AuthContext = createContext<AuthContextType>({
	isAuthenticated: false,
	token: null,
	login: async () => false,
	logout: () => {},
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [token, setToken] = useState<string | null>(() => {
		return localStorage.getItem('token');
	});

	const isAuthenticated = !!token;

	const login = async (
		username: string,
		password: string
	): Promise<boolean> => {
		try {
			const receivedToken = await authService.login(username, password);
			setToken(receivedToken);
			localStorage.setItem('token', receivedToken);
			return true;
		} catch (error) {
			console.error('Login failed:', error);
			return false;
		}
	};

	const logout = () => {
		setToken(null);
		localStorage.removeItem('token');
	};

	// Set Axios default headers whenever the token changes
	useEffect(() => {
		if (token) {
			axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
			axios.defaults.headers.common['X-API-KEY'] =
				process.env.REACT_APP_API_KEY; // Ensure this is set
		} else {
			delete axios.defaults.headers.common['Authorization'];
			delete axios.defaults.headers.common['X-API-KEY'];
		}
	}, [token]);

	return (
		<AuthContext.Provider value={{ isAuthenticated, token, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};

// Custom hook for using auth context
export const useAuth = () => useContext(AuthContext);
