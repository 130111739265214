import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { TextField, Button, Box } from '@mui/material';

interface CategoryFormProps {
	existingCategory: { categoryId?: number; categoryName: string } | null;
	onSuccess: (category: { categoryName: string }) => void; // Adjusted to use a simplified structure
	onCancel: () => void;
}

const CategoryForm: React.FC<CategoryFormProps> = ({
	existingCategory,
	onSuccess,
	onCancel,
}) => {
	const { register, handleSubmit } = useForm<{ categoryName: string }>({
		defaultValues: existingCategory || { categoryName: '' },
	});

	const onSubmit: SubmitHandler<{ categoryName: string }> = (data) => {
		onSuccess(data); // Pass the form data to handleFormSubmit
	};

	return (
		<Box
			component="form"
			onSubmit={handleSubmit(onSubmit)}>
			<TextField
				label="Category Name"
				{...register('categoryName', { required: true })}
				fullWidth
				margin="normal"
			/>
			<Button
				type="submit"
				variant="contained"
				color="primary">
				{existingCategory ? 'Update' : 'Create'}
			</Button>
			<Button
				variant="outlined"
				color="secondary"
				onClick={onCancel}
				sx={{ ml: 2 }}>
				Cancel
			</Button>
		</Box>
	);
};

export default CategoryForm;
