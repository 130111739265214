import React, { useEffect, useState } from 'react';
import { CategoryReadDto } from '../../types';
import categoryService from '../../services/categoryService';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	CircularProgress,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CategoryForm from './CategoryForm';
import ConfirmDialog from '../common/ConfirmDialog';

const Categories: React.FC = () => {
	const [categories, setCategories] = useState<CategoryReadDto[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [openForm, setOpenForm] = useState<boolean>(false);
	const [selectedCategory, setSelectedCategory] =
		useState<CategoryReadDto | null>(null);
	const [openConfirm, setOpenConfirm] = useState<boolean>(false);
	const [categoryToDelete, setCategoryToDelete] = useState<number | null>(null);

	const fetchCategories = async () => {
		try {
			const data = await categoryService.getCategories();
			setCategories(data);
		} catch (error) {
			console.error('Failed to fetch categories:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchCategories();
	}, []);

	const handleAdd = () => {
		setSelectedCategory(null);
		setOpenForm(true);
	};

	const handleEdit = (category: CategoryReadDto) => {
		setSelectedCategory(category);
		setOpenForm(true);
	};

	const handleDelete = (id: number) => {
		setCategoryToDelete(id);
		setOpenConfirm(true);
	};

	const confirmDelete = async () => {
		if (categoryToDelete !== null) {
			try {
				await categoryService.deleteCategory(categoryToDelete);
				setCategories((prevCategories) =>
					prevCategories.filter((c) => c.categoryId !== categoryToDelete)
				);
			} catch (error) {
				console.error('Failed to delete category:', error);
			} finally {
				setOpenConfirm(false);
				setCategoryToDelete(null);
			}
		}
	};

	const handleFormSubmit = async (categoryData: { categoryName: string }) => {
		try {
			if (selectedCategory) {
				// Edit existing category
				await categoryService.updateCategory(
					selectedCategory.categoryId,
					categoryData
				);
			} else {
				// Add new category
				await categoryService.createCategory(categoryData);
			}
			// Refetch categories to ensure the UI is up-to-date
			await fetchCategories();
		} catch (error) {
			console.error('Failed to submit category form:', error);
		} finally {
			setOpenForm(false);
		}
	};

	if (loading) return <CircularProgress />;

	return (
		<>
			<Button
				variant="contained"
				color="primary"
				onClick={handleAdd}
				sx={{ mb: 2 }}>
				Add Category
			</Button>
			<TableContainer component={Paper}>
				<Table aria-label="categories table">
					<TableHead>
						<TableRow>
							<TableCell>ID</TableCell>
							<TableCell>Category Name</TableCell>
							<TableCell align="right">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{categories.map((category) => (
							<TableRow key={category.categoryId}>
								<TableCell>{category.categoryId}</TableCell>
								<TableCell>{category.categoryName}</TableCell>
								<TableCell align="right">
									<IconButton
										color="primary"
										onClick={() => handleEdit(category)}>
										<EditIcon />
									</IconButton>
									<IconButton
										color="secondary"
										onClick={() => handleDelete(category.categoryId)}>
										<DeleteIcon />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
						{categories.length === 0 && (
							<TableRow>
								<TableCell
									colSpan={3}
									align="center">
									No categories found.
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>

			{/* Category Form Dialog */}
			<Dialog
				open={openForm}
				onClose={() => setOpenForm(false)}>
				<DialogTitle>
					{selectedCategory ? 'Edit Category' : 'Add Category'}
				</DialogTitle>
				<DialogContent>
					<CategoryForm
						existingCategory={selectedCategory}
						onSuccess={handleFormSubmit}
						onCancel={() => setOpenForm(false)}
					/>
				</DialogContent>
			</Dialog>

			{/* Confirm Delete Dialog */}
			<ConfirmDialog
				open={openConfirm}
				title="Delete Category"
				content="Are you sure you want to delete this category?"
				onConfirm={confirmDelete}
				onCancel={() => setOpenConfirm(false)}
			/>
		</>
	);
};

export default Categories;
